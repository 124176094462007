import { PaidPlans } from '@wix/ambassador-checkout-server/types';
import { Member } from '@wix/ambassador-members-ng-api/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import settingsParams from '../../components/BookingsForm/settingsParams';
import { PaymentOptionsIds } from '../../components/BookingsForm/ViewModel/PaymentViewModel/paymentViewModel';
import { PaymentMethod } from '../../types/types';
import { mapCatalogServiceToService, Service } from '../mappers/service.mapper';
import { BusinessInfoBase } from '@wix/bookings-uou-types';
import { ListSlotsResponse } from '@wix/ambassador-calendar-server/http';
import { GetActiveFeaturesResponse } from '@wix/ambassador-services-catalog-server/http';
import { SlotAvailability } from '@wix/ambassador-availability-calendar/types';
import { CatalogData } from '../../api/types';
import { TFunction } from '../../components/BookingsForm/controller';

export type FormState = {
  service: Service;
  isPricingPlanInstalled: boolean;
  hasCoupon: boolean;
  businessInfo: BusinessInfoBase;
  activeFeatures: GetActiveFeaturesResponse;
  slotAvailability?: SlotAvailability;
  numberOfParticipants: number;
  couponDiscount?: number;
  pricingPlanDetails?: PaidPlans;
  memberDetails?: Member;
  errors: any[];
  selectedPaymentOptionId?: string;
};

export const createInitialState = ({
  catalogData,
  slotAvailability,
  listSlots,
  memberDetails,
  pricingPlanDetails,
  t,
  settings,
  isPricingPlanInstalled,
}: {
  catalogData: CatalogData;
  slotAvailability: SlotAvailability;
  listSlots?: ListSlotsResponse;
  memberDetails?: Member;
  t: TFunction;
  pricingPlanDetails?: PaidPlans;
  settings: ControllerFlowAPI['settings'];
  isPricingPlanInstalled: boolean;
}): FormState => {
  const defaultPaymentOptionId = getDefaultPaymentOptionId(
    settings,
    pricingPlanDetails,
  );

  const service = mapCatalogServiceToService({
    catalogData,
    slotAvailability,
    pricingPlanDetails,
    t,
    listSlots,
  });

  return {
    activeFeatures: catalogData.activeFeatures,
    service,
    businessInfo: catalogData.businessInfo,
    slotAvailability,
    isPricingPlanInstalled,
    couponDiscount: 0,
    pricingPlanDetails,
    memberDetails,
    numberOfParticipants: 1,
    errors: [],
    selectedPaymentOptionId: defaultPaymentOptionId,
    hasCoupon: false,
  };
};

const getDefaultPaymentOptionId = (
  settings: ControllerFlowAPI['settings'],
  pricingPlanDetails?: PaidPlans,
) => {
  const planId = pricingPlanDetails?.defaultPlan?.paidPlan?.planId;
  if (planId) {
    return planId;
  } else {
    return settings.get(settingsParams.defaultPaymentMethod) ===
      PaymentMethod.SINGLE
      ? PaymentOptionsIds.SingleSession
      : PaymentOptionsIds.BuyAPricingPlan;
  }
};
